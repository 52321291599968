import * as React from "react";
import settings from "../styles/global/settings";
import Layout from "../components/Layout/Layout";
import PropTypes from "prop-types";
import SelectList from "../components/SelectList";
import { SCREENS } from "../helpers/screen-names-helper";

export enum MealsOptions {
  ONE = "1",
  TWO = "2",
  THREE = "3",
  MORE = "More than 3",
  GRAZE = "I'm a grazer",
}

const MealsQuestion = ({
  onClick,
  onBackClick,
  selectedOption,
  setSelection,
}: PropTypes.InferProps<typeof MealsQuestion.propTypes>): JSX.Element => {
  const buttons = [
    {
      title: `Continue`,
      onClick: () => onClick(),
      showBack: true,
      onBackClick: () => onBackClick(),
      disabled: !selectedOption,
    },
  ];
  const options = [];
  Object.values(MealsOptions).forEach((value) => {
    options.push({
      id: value,
      text: value,
    });
  });
  return (
    <Layout
      showProgress
      group={1}
      index={4}
      buttons={buttons}
      backgroundColor={settings.color.path}
      title={"How many meals do you usually have per day?"}
      subtitle={"Please select one"}
      segmentTitle={SCREENS.MealsQuestionScreen}
    >
      <SelectList
        smallPadding
        selectedItems={[selectedOption]}
        onSelect={(id) => {
          setSelection(id);
        }}
        items={options}
      />
    </Layout>
  );
};

MealsQuestion.propTypes = {
  onClick: PropTypes.func,
  onBackClick: PropTypes.func,
  selectedOption: PropTypes.string,
  setSelection: PropTypes.func,
};

export default MealsQuestion;
