import * as React from "react";
import SvgSplitScreen from "../components/SvgSplitScreen";
import settings from "../styles/global/settings";
import Layout from "../components/Layout/Layout";
import PropTypes from "prop-types";
import Image from "../svg/email.svg";
import { SCREENS } from "../helpers/screen-names-helper";
import { useRef, useState, useEffect } from "react";
import FieldTextInput from "../components/FieldTextInput.component";
import { addLead } from "../helpers/utility-helper";
import Checkbox from "../components/FieldCheckbox.component";
import { defaultAnswers } from "../types/constants";
import LeftArrow from "../svg/icon-back.svg";

const verifyEmail = function (email) {
  const regex = RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$");
  return regex.test(email);
};

const changeEmailWithEvent = function (newEmailEvent, setEmail) {
  setEmail(newEmailEvent.target.value);
};

const EmailScreen = ({
  onClick,
  onBackClick,
  email,
  setEmail,
  answers,
  setGdpr,
  setNewsletter,
}: PropTypes.InferProps<typeof EmailScreen.propTypes>): JSX.Element => {
  const firstRender = useRef(true);
  const [emailValid, setEmailValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [gdprChecked, setGdprChecked] = useState(defaultAnswers.gdpr);
  const [newsletterChecked, setNewsletterChecked] = useState(
    defaultAnswers.newsletter
  );
  useEffect(() => {
    if (firstRender.current && email.length < 1) {
      firstRender.current = false;
      return;
    } else if (firstRender.current) {
      firstRender.current = false;
    }

    const delayDebounceFn = setTimeout(() => {
      const isValid = verifyEmail(email);
      if (isValid) {
        setEmailValid(true);
        setError("");
      } else {
        setEmailValid(false);
        setError("Typo? Once more, please.");
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [email, setEmailValid]);

  useEffect(() => {
    setGdpr(gdprChecked);
  }, [gdprChecked]);

  useEffect(() => {
    setNewsletter(newsletterChecked);
  }, [newsletterChecked]);

  const buttons = [
    {
      title: `Submit`,
      disabled: !emailValid || isLoading || !gdprChecked,
      onClick: async () => {
        setIsLoading(true);
        const tryAddLead = await addLead(answers);
        if (tryAddLead) {
          onClick();
        } else {
          alert("Opps something went wrong");
          setIsLoading(false);
        }
      },
    },
  ];
  return (
    <Layout
      segmentTitle={SCREENS.EmailScreen}
      buttons={buttons}
      backgroundColor={settings.color.path}
    >
      <SvgSplitScreen
        backgroundColor={settings.color.primary_2}
        svg={<Image />}
      >
        <h4>
          Great news! We’re so pleased there’s a programme that suits you.
        </h4>
        <p>
          We’re working hard to launch this programme later this year. Share
          your email for early access and an exclusive 20% off when it arrives.
        </p>
        <FieldTextInput
          label={"Email"}
          value={email}
          onChange={(email) => changeEmailWithEvent(email, setEmail)}
          placeholder={"example@gmail.com"}
          errorMessage={error}
        />
        <Checkbox
          onChange={() => {
            setGdprChecked(!gdprChecked);
          }}
          checked={gdprChecked}
          label={
            <span>
              I agree with the Habitual{" "}
              <a
                style={{ textDecoration: "underline" }}
                target="_blank"
                href="https://www.tryhabitual.com/journal/terms"
                rel="noreferrer"
              >
                terms &amp; conditions
              </a>
            </span>
          }
        />
        <Checkbox
          onChange={() => {
            setNewsletterChecked(!newsletterChecked);
          }}
          checked={newsletterChecked}
          label={"Habitual can contact me via email"}
        />
      </SvgSplitScreen>
      <div
        onClick={onBackClick}
        style={{ position: "fixed", top: 40, left: 0 }}
      >
        <LeftArrow />
      </div>
    </Layout>
  );
};

EmailScreen.propTypes = {
  onClick: PropTypes.func,
  onBackClick: PropTypes.func,
  email: PropTypes.string,
  setEmail: PropTypes.func,
  answers: PropTypes.any,
  setGdpr: PropTypes.func,
  setNewsletter: PropTypes.func,
};

export default EmailScreen;
