import * as React from "react";
import SvgSplitScreen from "../components/SvgSplitScreen";
import settings from "../styles/global/settings";
import Layout from "../components/Layout/Layout";
import PropTypes from "prop-types";
import Fruit from "../svg/fruit.svg";
import { SCREENS } from "../helpers/screen-names-helper";

const NoFruitScreen = ({
  onClick,
  onBackClick,
}: PropTypes.InferProps<typeof NoFruitScreen.propTypes>): JSX.Element => {
  const buttons = [
    {
      title: `Continue`,
      onClick: () => onClick(),
      showBack: true,
      onBackClick: () => onBackClick(),
    },
  ];
  return (
    <Layout
      segmentTitle={SCREENS.NoFruitScreen}
      buttons={buttons}
      backgroundColor={settings.color.path}
    >
      <SvgSplitScreen
        backgroundColor={settings.color.primary_4}
        svg={<Fruit />}
      >
        <p>
          Perhaps whole food diets incorporating lots of fruits and veg aren’t a
          great fit for you. Let’s consider which other approaches might be more suitable.
        </p>
      </SvgSplitScreen>
    </Layout>
  );
};

NoFruitScreen.propTypes = {
  onClick: PropTypes.func,
  onBackClick: PropTypes.func,
};

export default NoFruitScreen;
