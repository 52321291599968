import * as React from "react";
import settings from "../styles/global/settings";
import Layout from "../components/Layout/Layout";
import PropTypes from "prop-types";
import SelectList from "../components/SelectList";
import { SCREENS } from "../helpers/screen-names-helper";

export enum ConcernsOptions {
  FAILURE = "Fear of failure",
  EMBARRASSMENT = "Embarrassment",
  COST = "Cost",
  DIETARY = "I have unique dietary requirements",
}

const ConcernsQuestion = ({
  onClick,
  onBackClick,
  selectedOption,
  setSelection,
}: PropTypes.InferProps<typeof ConcernsQuestion.propTypes>): JSX.Element => {
  const buttons = [
    {
      title: `Continue`,
      onClick: () => onClick(),
      showBack: true,
      onBackClick: () => onBackClick(),
      disabled: !selectedOption,
    },
  ];
  const options = [];
  Object.values(ConcernsOptions).forEach((value) => {
    options.push({
      id: value,
      text: value,
    });
  });
  return (
    <Layout
      showProgress
      group={2}
      index={2}
      buttons={buttons}
      backgroundColor={settings.color.path}
      title={"What’s your biggest concern about trying new approaches?"}
      subtitle={"Please select one"}
      segmentTitle={SCREENS.ConcernsQuestionScreen}
    >
      <SelectList
        smallPadding
        selectedItems={[selectedOption]}
        onSelect={(id) => {
          setSelection(id);
        }}
        items={options}
      />
    </Layout>
  );
};

ConcernsQuestion.propTypes = {
  onClick: PropTypes.func,
  onBackClick: PropTypes.func,
  selectedOption: PropTypes.string,
  setSelection: PropTypes.func,
};

export default ConcernsQuestion;
