import * as React from "react";
import AnimationSplitScreen from "../components/AnimationSplitScreen";
import settings from "../styles/global/settings";
import Layout from "../components/Layout/Layout";
import PropTypes from "prop-types";
import { SCREENS } from "../helpers/screen-names-helper";

const WelcomeScreen = ({
  onClick,
}: PropTypes.InferProps<typeof WelcomeScreen.propTypes>): JSX.Element => {
  const buttons = [
    {
      title: `Let’s get started`,
      onClick: () => {
        onClick();
      },
    },
  ];
  return (
    <Layout
      segmentTitle={SCREENS.WelcomeScreen}
      showHeader
      buttons={buttons}
      backgroundColor={settings.color.path}
    >
      <AnimationSplitScreen
        backgroundColor={settings.color.primary_3}
        animation={require("../animations/Welcome.json")}
      >
        <h4>Get your personalised diabetes reversal plan recommendation</h4>
        <p style={{ marginTop: "0px" }}>
          Answer just a few questions on food preferences, lifestyle, and
          motivation and we’ll recommend five programmes to help YOU to best
          achieve your goal.
        </p>
      </AnimationSplitScreen>
    </Layout>
  );
};

WelcomeScreen.propTypes = {
  onClick: PropTypes.func,
};

export default WelcomeScreen;
