import * as React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

interface SvgContainerProps {
  backgroundColor?: string;
}

const SvgContainer = styled.div.attrs(() => ({
  className: "SvgContainer",
}))`
  width: 100%;
  ${(props: SvgContainerProps) =>
    props.backgroundColor &&
    css`
        background-color: ${props.backgroundColor};
          }
        `};
`;

const ContentContainer = styled.div.attrs(() => ({
  className: "ContentContainer",
}))`
  max-width: 375px;
  margin: auto;
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  flex: 1;
  padding-bottom: 100px;
`;

const SvgSplitScreen = ({ svg, backgroundColor, children }: PropTypes.InferProps<typeof SvgSplitScreen.propTypes>): JSX.Element => {
  return (
    <>
      <SvgContainer backgroundColor={backgroundColor}>
        <div style={{ maxWidth: "600px", margin: "auto", textAlign: "center" }}>
          {svg}
        </div>
      </SvgContainer>
      <ContentContainer>{children}</ContentContainer>
    </>
  );
};

SvgSplitScreen.propTypes = {
  svg: PropTypes.any,
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
};

export default SvgSplitScreen;
