import * as React from "react";
import settings from "../styles/global/settings";
import Layout from "../components/Layout/Layout";
import PropTypes from "prop-types";
import SelectList from "../components/SelectList";
import { SCREENS } from "../helpers/screen-names-helper";

export enum ToolsOptions {
  TIPS_AND_TRICKS = "Short tips and tricks",
  BLOGS = "Blog style articles",
  JOURNAL = "Journal records of your progress",
  EXERCISES = "Written exercises",
}

const ToolsQuestion = ({
  onClick,
  onBackClick,
  selectedOption,
  setSelection,
}: PropTypes.InferProps<typeof ToolsQuestion.propTypes>): JSX.Element => {
  const buttons = [
    {
      title: `Continue`,
      onClick: () => onClick(),
      showBack: true,
      onBackClick: () => onBackClick(),
      disabled: !selectedOption,
    },
  ];
  const options = [];
  Object.values(ToolsOptions).forEach((value) => {
    options.push({
      id: value,
      text: value,
    });
  });
  return (
    <Layout
      showProgress
      group={2}
      index={4}
      buttons={buttons}
      backgroundColor={settings.color.path}
      title={"What learning tools do you think you could benefit from?"}
      subtitle={"Please select one"}
      segmentTitle={SCREENS.ToolsQuestionScreen}
    >
      <SelectList
        smallPadding
        selectedItems={[selectedOption]}
        onSelect={(id) => {
          setSelection(id);
        }}
        items={options}
      />
    </Layout>
  );
};

ToolsQuestion.propTypes = {
  onClick: PropTypes.func,
  onBackClick: PropTypes.func,
  selectedOption: PropTypes.string,
  setSelection: PropTypes.func,
};

export default ToolsQuestion;
