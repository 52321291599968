import React from "react"
import styled from "styled-components"
import settings from "../styles/global/settings"
import PropTypes from "prop-types";

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  :focus + & {
    box-shadow: 0 0 0 3px ${settings.color.primary_3};
  }
`

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  background: ${props =>
    props.checked ? settings.color.primary_3 : settings.color.white};
  border-color: ${props =>
    props.checked ? settings.color.primary_3 : settings.color.primary_2};
  border-width: 2px;
  border-style: solid;
  border-radius: 3px;
  transition: all 150ms;
`

const CheckboxContainer = styled.div`
  vertical-align: middle;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
`

const Container = styled.div`
  font-family: ${settings.font.default};
  color: ${settings.color.primary_1};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  flex-direction: row;
  display: flex;
`

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Checkbox = ({checked, label, ...props }) => (
  <label>
    <Container>
      <CheckboxContainer>
        <HiddenCheckbox checked={checked} {...props} />
        <StyledCheckbox checked={checked}>
          {checked && (
            <Icon viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </Icon>
          )}
        </StyledCheckbox>
      </CheckboxContainer>
      <div
        style={{
          display: "flex",
          paddingLeft: "5px",
          marginTop: "auto",
          marginBottom: "auto",
          textAlign: "left",
          fontSize: "15px",
        }}
      >
        {label}
      </div>
    </Container>
  </label>
)

Checkbox.propTypes = {
  label: PropTypes.any,
  checked: PropTypes.bool,
};

export default Checkbox
