import React from "react";
import styled, { css } from "styled-components";
import settings from "../styles/global/settings";
import PropTypes from "prop-types";

interface InputProps {
  isErrorState: boolean;
}

const HabFieldTextInput = styled.div.attrs(() => ({
  className: "HabFieldTextInput",
}))`
  text-align: left;
  margin-top: 25px;
  margin-bottom: 25px;
  label {
    color: ${settings.color.primary_1};
    font-family: DM Sans;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
  }

  input {
    background: ${settings.color.white};
    border-radius: 3px;
    border: 1px solid ${settings.color.grey};
    padding-top: 14px;
    padding-bottom: 14px;
    padding-right: 16px;
    padding-left: 16px;
    width: 100%;
    margin-top: 8px;
    ${(props: InputProps) =>
      props.isErrorState &&
      css`
        border: 1px solid ${settings.color.error};
      `};
  }
`;

const HabErrorMessage = styled.div.attrs(() => ({
  className: "HabErrorMessage",
}))`
  font-size: 14px;
  color: ${settings.color.error};
  padding-top: 5px;
`;

const HabOptionalMessage = styled.div.attrs(() => ({
  className: "HabOptionalMessage",
}))`
  font-size: 14px;
  opacity: 0.7;
  margin-left: auto;
`;

const FieldTextInput = ({
  label,
  value,
  onChange,
  placeholder,
  errorMessage,
  optional,
}: PropTypes.InferProps<typeof FieldTextInput.propTypes>): JSX.Element => {
  const isErrorState = errorMessage && errorMessage.length > 1;

  return (
    <HabFieldTextInput isErrorState={isErrorState}>
      <label>
        <span style={{ display: "flex" }}>
          {label}{" "}
          {optional && <HabOptionalMessage>(optional)</HabOptionalMessage>}
        </span>
        <input
          type="text"
          value={value}
          placeholder={placeholder}
          onChange={(value) => onChange(value)}
        />
        {errorMessage && errorMessage.length > 1 && (
          <HabErrorMessage>{errorMessage}</HabErrorMessage>
        )}
      </label>
    </HabFieldTextInput>
  );
};

FieldTextInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  optional: PropTypes.bool,
};

export default FieldTextInput;
