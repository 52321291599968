import * as React from "react";
import settings from "../styles/global/settings";
import Layout from "../components/Layout/Layout";
import PropTypes from "prop-types";
import SelectList from "../components/SelectList";
import { SCREENS } from "../helpers/screen-names-helper";

export enum BeansAndPulsesOptions {
  OFTEN = "Often",
  SOMETIMES = "Sometimes",
  NEVER = "Never",
}

const BeansAndPulsesQuestion = ({
  onClick,
  onBackClick,
  selectedOption,
  setSelection,
}: PropTypes.InferProps<typeof BeansAndPulsesQuestion.propTypes>): JSX.Element => {
  const buttons = [
    {
      title: `Continue`,
      onClick: () => onClick(),
      showBack: true,
      onBackClick: () => onBackClick(),
      disabled: !selectedOption,
    },
  ];
  const options = []
  Object.values(BeansAndPulsesOptions).forEach((value) => {
    options.push({
      id: value,
      text: value,
    })
  });
  return (
    <Layout
      showProgress
      group={0}
      index={7}
      buttons={buttons}
      backgroundColor={settings.color.path}
      title={"How frequently do you eat beans and pulses? "}
      example={'(eg lentils, kidney beans)'}
      subtitle={"Please select one"}
      segmentTitle={SCREENS.BeansAndPulsesScreen}
    >
      <SelectList
        smallPadding
        selectedItems={[selectedOption]}
        onSelect={(id) => {
          setSelection(id);
        }}
        items={options}
      />
    </Layout>
  );
};

BeansAndPulsesQuestion.propTypes = {
  onClick: PropTypes.func,
  onBackClick: PropTypes.func,
  selectedOption: PropTypes.string,
  setSelection: PropTypes.func,
};

export default BeansAndPulsesQuestion;
