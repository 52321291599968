import * as React from "react";
import settings from "../styles/global/settings";
import Layout from "../components/Layout/Layout";
import PropTypes from "prop-types";
import { SCREENS } from "../helpers/screen-names-helper";
import Button from "../components/Button.component";

const RecommendationDetailScreen = ({
  onClick,
  onBackClick,
  content,
  title
}: PropTypes.InferProps<
  typeof RecommendationDetailScreen.propTypes
>): JSX.Element => {
  return (
    <Layout
      segmentTitle={SCREENS.RecommendationDetailScreen}
      backgroundColor={settings.color.path}
      smallHeader
      headerTitle={title}
    >
      <div style={{
        paddingLeft: '15px',
        paddingRight: '15px',
        paddingTop: '40px',
        maxWidth: '375px',
        margin: 'auto',
      }}>
      {content.map((c: string, index: number) => {
        return <p key={`content-${index}`}>{c}</p>;
      })}
      </div>
      <Button onClick={onBackClick} title={"Back to recommendations"} />
      {onClick && <Button secondary onClick={onClick} title={"Learn more"} />}

    </Layout>
  );
};

RecommendationDetailScreen.propTypes = {
  onClick: PropTypes.func,
  onBackClick: PropTypes.func,
  content: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
};

export default RecommendationDetailScreen;
