import * as React from "react";
import settings from "../styles/global/settings";
import Layout from "../components/Layout/Layout";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import RecommendationNumbers from "../components/RecommendationNumbers";
import RecommendationCard from "../components/RecommendationCard";
import LowCarbGraph from "../svg/low-carb-graph.svg";
import KetoGraph from "../svg/keto-graph.svg";
import WholefoodsGraph from "../svg/wholefood-graph.svg";
import TDRGraph from "../svg/tdr-graph.svg";
import TDRPlusGraph from "../svg/tdr-plus-graph.svg";
import Button from "../components/Button.component";
// import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { ProgrammeTypes } from "../types/constants";
import { SCREENS } from "../helpers/screen-names-helper";
import { scrollToTop } from "../helpers/utility-helper";
import RecommendationSideArrow from "../components/RecommendationSideArrow";
import { useSwipeable } from "react-swipeable";
const swipeConfig = {
  delta: 10, // min distance(px) before a swipe starts. *See Notes*
  preventDefaultTouchmoveEvent: false, // call e.preventDefault *See Details*
  trackTouch: true, // track touch input
  trackMouse: false, // track mouse input
  rotationAngle: 0, // set a rotation angle
};
import { isMobile } from "react-device-detect";
import {
  trackProgrammeRender,
  trackProgrammeViewed,
} from "../helpers/segment-helper";

export enum titles {
  TOP = "Your top recommendation",
  SECOND = "Your 2nd recommendation",
  THIRD = "Your 3rd recommendation",
  FOURTH = "Your 4th recommendation",
  FIFTH = "Your 5th recommendation",
}

export enum footers {
  TOP = "Your top recommendation",
  SECOND = "1 recommendations left...",
  THIRD = "2 recommendations left...",
  FOURTH = "3 recommendations left...",
  FIFTH = "4 recommendations left...",
}

const RecommendationsScreen = ({
  onClick,
  onInfoClick,
  scores,
  indexInFocus,
}: PropTypes.InferProps<
  typeof RecommendationsScreen.propTypes
>): JSX.Element => {
  const programmes = [
    {
      type: ProgrammeTypes.LOW_CARB,
      title: "Low-carb diet",
      graph: <LowCarbGraph />,
      description:
        "Limit carbohydrate and focus on high-protein, high-fibre foods.",
      research:
        "After 1-year of a Low-Carbohydrate Type 2 Diabetes Self-Management Program, 40.4% reduced their diabetes medication, and lost an average of 6.9% of their body weight!",
      reference: "(Saslow et al., 2018)",
      positives: [
        "Enables meal flexibility",
        "Gets blood sugar quickly under control",
        "Incorporates small amounts of carb (50-150g) per day",
        "Less restrictive than other diets",
      ],
      negatives: [
        "Slower rates of weight loss",
        "Slower rates of lowering of HbA1C",
      ],
      cost: 2,
      costDescription: "£39 per month",
      longDescription: [
        "Refined carbohydrates (white bread, white rice etc) are just hidden simple sugars, so it’s no wonder that eating too many challenges our glycaemic control and leaves us with blood sugar spikes. Unlike our keto programme, a low-carb diet limits (rather than excludes) carbohydrate intake to prevent those pesky spikes and keep our blood sugar levels happy.",
        "This diet is characterised by high-protein, high-fibre foods, similar to our keto diet, but is less rich and more diverse, making it easier to maintain. It is not as calorie restrictive as our other dietary options, which may mean a slower rate of weight loss and rate of remission, but it is a good option for those looking to ease themselves into a new sustainable lifestyle.",
      ],
      learnMoreUrl:
        "https://www.tryhabitual.com/journal/can-a-low-carb-diet-help-manage-diabetes",
      button: (
        <Button
          buttonStyle={{ width: "100%" }}
          bold
          title={"Sounds right for me"}
          onClick={() => {
            onClick(ProgrammeTypes.LOW_CARB, sortedProgrammes);
          }}
        />
      ),
    },
    {
      type: ProgrammeTypes.KETO,
      title: "Ketogenic diet",
      graph: <KetoGraph />,
      description:
        "Significantly restrict carbohydrate intake to less than 20% total calorie intake to start burning fat instead of glucose for fuel.",
      research:
        "In a 2-year ketogenic intervention, 91% of patients on insulin either reduced or eliminated their insulin dosage (Athinarayanan et al., 2019) and 52% of patients achieved normoglycaemia.",
      reference: "(McKenzie et al., 2021)",
      positives: [
        "Gets blood sugar quickly under control",
        "Allows high-fat foods",
        "Creates filling meals",
      ],
      negatives: [
        "Quite restrictive",
        "Difficult to maintain",
        "May experience side effects known as 'carb-flu'",
        "Not advised as a long-term solution",
        "Can be expensive",
      ],
      cost: 3,
      costDescription: "£39 per month",
      longDescription: [
        "A ketogenic diet gets its name from the effect it has on the body. With its very low carbohydrate intake, a keto diet switches your metabolism from burning glucose to burning fat. This is known as ketosis and means your body is being fuelled by ketones, a unit of energy produced from fat. As you burn through your fat stores, those extra pounds start to drop off!",
        "The keto diet is characterised by a high intake of protein and fat, such as high-fat dairy, nuts and seeds, meat and poultry, eggs, and cheese (phew!). We also have a vegetarian version to glorify those protein-packed legumes.",
        "For people who don’t love rich foods it can be a tough diet to follow as most fruits, grains, low-fat products, and fizzy beverages are excluded. Weight loss and remission may happen at a slower rate because it isn’t as calorie restrictive as our other programmes but for many, the familiar fat and protein content makes it a comfortable starting point for a new challenge.",
      ],
      learnMoreUrl:
        "https://www.tryhabitual.com/journal/rethinking-fat-lets-talk-about-the-keto-diet",
      button: (
        <Button
          bold
          buttonStyle={{ width: "100%" }}
          title={"Sounds right for me"}
          onClick={() => {
            onClick(ProgrammeTypes.KETO, sortedProgrammes);
          }}
        />
      ),
    },
    {
      title: "Whole food 800",
      type: ProgrammeTypes.WHOLEFOODS,
      graph: <WholefoodsGraph />,
      description:
        "Eat 800kcal made with whole food every day for 3 months. Once you hit your goal weight, reintroduce food and find your 'new normal'.",
      research:
        "In an 800kcal intervention trial, the DIAMOND study, patients saw a huge reduction in their HbA1c, on average reducing it by 16.3 mmol by just week 12! ",
      reference: "(Morris et al., 2017)",
      positives: [
        "Enables meal flexibility",
        "Offers a wide range of fresh foods",
        "Sustainable in the long-term",
        "Creates filling meals",
        "Good for your gut microbiome",
      ],
      negatives: ["Requires more meal preparation"],
      cost: 2,
      costDescription: "£39 per month",
      longDescription: [
        "Dietary fibre is the key difference when comparing our wholefoods 800 and low-carb diets. Dietary fibre is found in whole grain carbohydrates, nuts, seeds, fruits, and vegetables, and slows the release of sugar into the bloodstream.",
        "This immediately prevents the high influx of sugar that your body struggles to cope with. Not only does dietary fibre help with sugar control, it also increases good bacteria in your gut to reduce toxins and assists with weight loss!",
        "These types of foods are naturally low in calories, so 800kcal will stretch surprisingly far. If you’re interested in fast results and getting creative in your kitchen, this is a great choice - the pounds will fly off!",
      ],
      button: (
        <Button
          bold
          buttonStyle={{ width: "100%" }}
          title={"Sounds right for me"}
          onClick={() => {
            onClick(ProgrammeTypes.WHOLEFOODS, sortedProgrammes);
          }}
        />
      ),
    },
    {
      title: "Total Diet Replacement",
      type: ProgrammeTypes.TDR,
      graph: <TDRGraph />,
      description:
        "Consume 4 nutritionally complete shakes or soups a day for 3 months before gradually reintroducing new food habits.",
      research:
        "DiRECT trial saw 46% of their participants achieving diabetes remission at 12-months and 86% patients achieving remission who lost 15kg or more.",
      reference: "(Lean et al., 2018)",
      positives: [
        "Convenient",
        "Quick to prepare",
        "Straightforward",
        "Rapid weight loss and HbA1C level reduction",
        "Best value for money",
      ],
      negatives: ["Not as flexible as other programmes"],
      cost: 2,
      costDescription: "£39 per week for all your food",
      longDescription: [
        "Our total diet replacement consists of a range of delicious shakes, soups, and porridges, each representing a 200kcal meal. Four meals spread out across the day totals a daily calorie count of 800kcal. Due to the calorie reduction, our patients experience very fast weight loss results straight away, giving people with type 2 diabetes the best chance of reaching diabetes reversal.",
        "Backed by large clinical trials and trusted academics, and formulated by regulatory boards, you can relax knowing all your nutritional needs will be met. If you have a busy lifestyle then this is a great choice - with no planning, cooking, or washing up required, it’s just so easy!",
      ],
      learnMoreUrl:
        "https://www.tryhabitual.com/journal/what-is-total-diet-replacement-is-it-right-for-me",
      button: (
        <Button
          bold
          buttonStyle={{ width: "100%" }}
          title={"Sounds right for me"}
          onClick={() => {
            onClick(ProgrammeTypes.TDR, sortedProgrammes);
          }}
        />
      ),
    },
    {
      title: "Replacement 800",
      type: ProgrammeTypes.TDR_PLUS,
      graph: <TDRPlusGraph />,
      description:
        "Consume 3 nutritionally complete shakes or soups and a 200kcal meal each day for 3 months before enjoying a new, healthier diet.",
      research:
        "An interventional cohort study saw 10kg weight loss in 6 months with this diet, leading to a 13 mmol/mol reduction in HbA1C and 62% decrease in 10-year cardiovascular disease risk.",
      reference: "(Melhelm et al., 2021)",
      positives: [
        "Convenient",
        "Quick to prepare",
        "Straightforward",
        "Rapid weight loss and HbA1C level reduction",
        "Best value for money",
      ],
      negatives: [
        "Slower rates of weight loss",
        "Slower rates of lowering HbA1C",
      ],
      cost: 1,
      costDescription: "£29 per week for most of your food",
      longDescription: [
        "Total diet replacement lite consists of a range of delicious shakes, soups, and porridges, each representing a 200kcal meal. Unlike our TDR programme, the idea is to only have three of our TDR meals a day, totalling 600kcal, as well as 200kcal of fresh, non-starchy veg.",
        "This variant of TDR is for those who enjoy the convenience of our sachet meals but still want to cook one meal per day. This makes it perfect if you feel a little hesitant going head first into meal replacements. This diet, also termed the ‘Newcastle diet’, has been rigorously tested and has a lot of support as a tool to achieve type 2 diabetes remission.",
      ],
      button: (
        <Button
          bold
          buttonStyle={{ width: "100%" }}
          title={"Sounds right for me"}
          onClick={() => {
            onClick(ProgrammeTypes.TDR_PLUS, sortedProgrammes);
          }}
        />
      ),
    },
  ];

  const [recommendation, setRecommendation] = useState(indexInFocus || 0);
  const [title, setTitle] = useState(titles.TOP);
  const [footer, setFooter] = useState(footers.TOP);
  const [sortedProgrammes, setSortedProgrammes] = useState([])

  useEffect(() => {
    const sortedScores = scores.sort((a, b) => {
      return a.match > b.match ? +1 : -1;
    });
    // loop over sorted scores and push that programme onto the sorted programmes array
    const sorted = sortedScores.map((s) => {
      const { type } = s;
      const matchingPrgramme = programmes.filter((p) => {
        return p.type === type;
      })[0];
      return matchingPrgramme
    })
    setSortedProgrammes(sorted);
    trackProgrammeRender(sorted);
    sorted.length && trackProgrammeViewed(sorted, sorted[0].type);
  }, []);

  useEffect(() => {
    if (recommendation === 4) {
      setTitle(titles.TOP);
      setFooter(footers.TOP);
    } else if (recommendation === 3) {
      setTitle(titles.SECOND);
      setFooter(footers.SECOND);
    } else if (recommendation === 2) {
      setTitle(titles.THIRD);
      setFooter(footers.THIRD);
    } else if (recommendation === 1) {
      setTitle(titles.FOURTH);
      setFooter(footers.FOURTH);
    } else if (recommendation === 0) {
      setTitle(titles.FIFTH);
      setFooter(footers.FIFTH);
    }
    scrollToTop();
    sortedProgrammes.length && trackProgrammeViewed(
      sortedProgrammes,
      sortedProgrammes[recommendation].type
    );
  }, [recommendation]);

  const goForward = () => {
    if (recommendation < sortedProgrammes.length - 1) {
      setRecommendation(recommendation + 1);
    }
  };

  const goBack = () => {
    if (recommendation > 0) {
      setRecommendation(recommendation - 1);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      goForward();
    },
    onSwipedRight: () => {
      goBack();
    },
    ...swipeConfig,
  });

  const [showFixedHeader, setShowFixedHeader] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 59) {
        if (!showFixedHeader) {
          setShowFixedHeader(true);
        }
      } else if (showFixedHeader) {
        setShowFixedHeader(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showFixedHeader]);

  return (
    <React.Fragment>
      {sortedProgrammes.length &&
        <Layout
          segmentTitle={SCREENS.RecommendationScreen}
          backgroundColor={settings.color.path}
          yellowHeader
        >
          <p
            style={{
              marginTop: "39px",
              fontSize: "16px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {title}
          </p>
          <RecommendationNumbers
            selectedIndex={recommendation}
            change={(index: number) => {
              setRecommendation(index);
            }}
            count={programmes.length}
            showArrows
            onBackClick={goBack}
            onForwardClick={goForward}
          />
          <p
            style={{
              marginTop: "12px",
              fontSize: "14px",
              textAlign: "center",
              marginBottom: "0px",
              paddingRight: "12px",
              paddingLeft: "12px",
            }}
          >
            {isMobile
              ? "Browse your personalised programme recommendations by tapping the numbers, the arrows or swiping."
              : "Browse your personalised programme recommendations by clicking the numbers or arrows"}
          </p>

          <div {...handlers}>
            <RecommendationCard
              programme={sortedProgrammes[recommendation]}
              footer={
                <>
                  <p
                    style={{
                      marginTop: "39px",
                      fontSize: "16px",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {footer}
                  </p>
                  <RecommendationNumbers
                    selectedIndex={recommendation}
                    change={(index: number) => {
                      setRecommendation(index);
                      scrollToTop();
                    }}
                    count={5}
                    yellow
                  />
                </>
              }
              match={
                scores.filter((s) => {
                  return s.type === sortedProgrammes[recommendation].type;
                })[0]?.match
              }
              onInfoClick={() => {
                onInfoClick({
                  content: sortedProgrammes[recommendation].longDescription,
                  url: sortedProgrammes[recommendation].learnMoreUrl,
                  title: sortedProgrammes[recommendation].title,
                  index: recommendation,
                });
              }}
            />
          </div>
          {showFixedHeader && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                backgroundColor: settings.color.primary_2,
                paddingTop: "10px",
                paddingBottom: "8px",
              }}
            >
              <div style={{ maxWidth: "298px", margin: "auto" }}>
                <RecommendationNumbers
                  selectedIndex={recommendation}
                  change={(index: number) => {
                    setRecommendation(index);
                  }}
                  count={programmes.length}
                  showArrows
                  onBackClick={goBack}
                  onForwardClick={goForward}
                />
              </div>
              <p
                style={{
                  marginTop: "12px",
                  fontSize: "14px",
                  textAlign: "center",
                  marginBottom: "0px",
                  paddingRight: "12px",
                  paddingLeft: "12px",
                }}
              >
                {isMobile
                  ? "Browse your personalised programme recommendations by tapping the numbers, the arrows or swiping."
                  : "Browse your personalised programme recommendations by clicking the numbers or arrows"}
              </p>
            </div>
          )}
          <RecommendationSideArrow
            isRight
            onClick={() => {
              if (recommendation < sortedProgrammes.length - 1) {
                setRecommendation(recommendation + 1);
              }
            }}
          />
          <RecommendationSideArrow
            onClick={() => {
              if (recommendation > 0) {
                setRecommendation(recommendation - 1);
              }
            }}
          />
        </Layout>
      }
    </React.Fragment>
  );
};

RecommendationsScreen.propTypes = {
  onClick: PropTypes.func,
  // onBackClick: PropTypes.func,
  onInfoClick: PropTypes.func,
  scores: PropTypes.any,
  indexInFocus: PropTypes.number,
};

export default RecommendationsScreen;
