import * as React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import settings from "../styles/global/settings";
import LeftArrow from "../svg/icon-back.svg";
import RightArrow from "../svg/icon-forward.svg";
const Container = styled.div.attrs(() => ({
  className: "Container",
}))`
  max-width: 375px;
  margin: auto;
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: row;
`;
interface NumProps {
  selected?: boolean;
  yellow?: boolean;
  isRight?: boolean;
  isDisabled?: boolean;
}
const Number = styled.div.attrs(() => ({
  className: "Number",
}))`
  width: 32px;
  height: 32px;
  margin: auto;
  background-color: #ffffff;
  border-radius: 19px;
  margin-left: 6px;
  font-size: 18px;
  display: flex;
  font-family: ${settings.font.headings};
  color: ${settings.color.primary_1};
  text-align: center;
  opacity: 40%;
  cursor: pointer;
  line-height: 32px;
  ${(props: NumProps) =>
    props.selected === true &&
    props.yellow !== true &&
    css`
      opacity: 1;
      cursor: no-drop;
    `};
  ${(props: NumProps) =>
    props.yellow === true &&
    css`
      background-color: ${settings.color.path};
      opacity: 1;
    `};
  ${(props: NumProps) =>
    props.yellow === true &&
    props.selected === true &&
    css`
      background-color: ${settings.color.primary_2};
    `};
`;

const Arrow = styled.div.attrs(() => ({
  className: "Arrow",
}))`
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 19px;
  display: flex;
  text-align: center;
  cursor: pointer;
  margin-right: 14px;
  margin-left: 0px;
  ${(props: NumProps) =>
    props.selected === true &&
    props.yellow !== true &&
    css`
      opacity: 1;
      cursor: no-drop;
    `};
  ${(props: NumProps) =>
    props.yellow === true &&
    css`
      background-color: ${settings.color.path};
      opacity: 1;
    `};
  ${(props: NumProps) =>
    props.yellow === true &&
    props.selected === true &&
    css`
      background-color: ${settings.color.primary_2};
    `};
  ${(props: NumProps) =>
    props.isRight === true &&
    css`
      margin-left: 14px;
      margin-right: 0px;
    `};
  ${(props: NumProps) =>
    props.isDisabled === true &&
    css`
      opacity: 40%;
      cursor: no-drop;
    `};
`;

const RecommendationNumbers = ({
  selectedIndex,
  change,
  count,
  yellow,
  showArrows,
  onForwardClick,
  onBackClick,
}: PropTypes.InferProps<
  typeof RecommendationNumbers.propTypes
>): JSX.Element => {
  const numbers = [];
  for (let i = 0; i < count; i++) {
    numbers.push(
      <Number
        key={`number-${i}`}
        yellow={yellow}
        onClick={() => change(i)}
        selected={i === selectedIndex ? true : false}
      >
        <div style={{ margin: "auto" }}>{5 - i}</div>
      </Number>
    );
  }
  return (
    <>
      <Container>
        {showArrows && onBackClick && (
          <Arrow
            key={`back-arrow`}
            onClick={() => {
              onBackClick();
            }}
            yellow={yellow}
            isDisabled={selectedIndex === 0}
          >
            <LeftArrow style={{ height: "100%", width: "100%" }} />
          </Arrow>
        )}
        {numbers.map((i: JSX.Element) => {
          return i;
        })}
        {showArrows && onForwardClick && (
          <Arrow
            isDisabled={selectedIndex === 4}
            key={`forward-arrow`}
            onClick={() => {
              onForwardClick();
            }}
            yellow={yellow}
            isRight
          >
            <RightArrow style={{ height: "100%", width: "100%" }} />
          </Arrow>
        )}
      </Container>
    </>
  );
};

RecommendationNumbers.propTypes = {
  selectedIndex: PropTypes.number,
  change: PropTypes.func,
  count: PropTypes.number,
  yellow: PropTypes.bool,
  showArrows: PropTypes.bool,
  onForwardClick: PropTypes.func,
  onBackClick: PropTypes.func,
};

export default RecommendationNumbers;
