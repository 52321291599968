import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import LeftArrow from "../svg/icon-back.svg";
import RightArrow from "../svg/icon-forward.svg";
import settings from "../styles/global/settings";

interface HabRecommendationSideArrowContainerProps {
  isRight?: boolean;
}

const HabRecommendationSideArrowContainer = styled.div.attrs(() => ({
  className: "HabRecommendationSideArrowContainer",
}))`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  z-index: 5;
  position: fixed;
  bottom: 45%;
  left: 1px;
  height: 48px;
  width: 48px;
  background-color: ${settings.color.shadow};
  border-radius: 24px;
  cursor: pointer;

  @media (min-width: ${settings.bp.small.view}) {
    left: 80px;
  }

  ${(props: HabRecommendationSideArrowContainerProps) =>
    props.isRight &&
    css`
      right: 1px;
      margin-left: auto;
      @media (min-width: ${settings.bp.small.view}) {
        right: 80px;
      }
    `};
`;

const RecommendationSideArrow = ({
  isRight,
  onClick,
}: PropTypes.InferProps<
  typeof RecommendationSideArrow.propTypes
>): JSX.Element => {
  return (
    <HabRecommendationSideArrowContainer
      isRight={isRight}
      onClick={() => onClick()}
    >
      {isRight ? <RightArrow /> : <LeftArrow />}
    </HabRecommendationSideArrowContainer>
  );
};

RecommendationSideArrow.propTypes = {
  isRight: PropTypes.bool,
  onClick: PropTypes.any,
};

export default RecommendationSideArrow;
