import * as React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import settings from "../styles/global/settings";
import InfoIcon from "../svg/icon-info.svg";

const Container = styled.div.attrs(() => ({
  className: "Container",
}))`
  margin-top: 39px;
  margin-bottom: 89px;
  margin-left: 24px;
  margin-right: 24px;
  max-width: 375px;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  flex: 1;
  background-color: ${settings.color.white};
  border-radius: 15px;
  @media (min-width: 376px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 375px;
  }
`;

const Match = styled.div.attrs(() => ({
  className: "Match",
}))`
  background-color: ${settings.color.primary_4};
  border-radius: 40px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  max-width: 140px;
  margin-top: 5px;
  margin-bottom: 12px;
`;

const Bullet = styled.div.attrs(() => ({
  className: "Bullet",
}))`
  font-size: 16px;
  margin: 0px;
  margin-top: 8px;
  line-height: 130%;
  display: list-item;
  list-style-position: outside;
  margin-left: 28px;
`;

const RecommendationCard = ({
  programme,
  footer,
  match,
  onInfoClick,
}: PropTypes.InferProps<typeof RecommendationCard.propTypes>): JSX.Element => {
  const cost = [];
  for (let i = 0; i < 3; i++) {
    if (i < programme.cost) {
      cost.push(
        <h4
          key={`cost-icon-${i}`}
          style={{
            fontSize: "18px",
            marginBottom: "9px",
            textAlign: "left",
            marginTop: "0px",
            marginLeft: "4px",
          }}
        >
          £
        </h4>
      );
    } else {
      cost.push(
        <h4
          key={`cost-icon-${i}`}
          style={{
            fontSize: "18px",
            marginBottom: "9px",
            textAlign: "left",
            marginTop: "0px",
            opacity: 0.6,
            marginLeft: "4px",
          }}
        >
          £
        </h4>
      );
    }
  }

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <h4 style={{ textAlign: "left", marginTop: "19px", marginBottom: "4px" }}>
          {programme.title}
        </h4>
        <div
          onClick={() => {
            onInfoClick();
          }}
          style={{
            cursor: 'pointer',
            marginTop: "19px",
          }}
        >
          <InfoIcon />
        </div>
      </div>
      {match && <Match>
        <p
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            textAlign: "center",
            margin: "0px",
          }}
        >
          {match}% match
        </p>
      </Match>}
      {programme.description && (
        <p
          style={{
            fontSize: "16px",
            textAlign: "left",
          }}
        >
          {programme.description}
        </p>
      )}
      {programme.graph}
      {programme.button && programme.button}
      {programme.research && (
        <>
          <h4
            style={{
              fontSize: "18px",
              marginBottom: "9px",
              textDecorationLine: "underline",
              textDecorationColor: settings.color.primary_2,
              textDecorationThickness: "2px",
              textUnderlineOffset: "3px",
              textAlign: "left",
              marginTop: "39px",
            }}
          >
            Research
          </h4>
          <p
            style={{
              fontSize: "16px",
              textAlign: "left",
              margin: "0px",
            }}
          >
            {programme.research}
          </p>
          {programme.reference && (
            <p
              style={{
                fontSize: "16px",
                textAlign: "right",
                opacity: 0.7,
                marginTop: "8px",
              }}
            >
              {programme.reference}
            </p>
          )}
        </>
      )}
      {programme.positives && (
        <div
          style={{
            backgroundColor: settings.color.light_green,
            marginLeft: "-16px",
            marginRight: "-16px",
            paddingTop: "22px",
            paddingBottom: "34px",
            paddingLeft: "16px",
            paddingRight: "16px",
            borderLeftStyle: "solid",
            borderColor: settings.color.primary_4,
            borderLeftWidth: "3px",
          }}
        >
          <h4
            style={{
              fontSize: "18px",
              marginBottom: "9px",
              textDecorationLine: "underline",
              textDecorationColor: settings.color.primary_4,
              textUnderlineOffset: "3px",
              textDecorationThickness: "2px",
              textAlign: "left",
              marginTop: "0px",
            }}
          >
            Positives
          </h4>
          <p
            style={{
              fontSize: "16px",
              textAlign: "left",
              margin: "0px",
            }}
          >
            {programme.positives.map((p, index) => {
              return <Bullet key={`positive-point-${index}`}>{p}</Bullet>;
            })}
          </p>
        </div>
      )}
      {programme.negatives && (
        <div
          style={{
            backgroundColor: settings.color.heart_red_semi,
            marginLeft: "-16px",
            marginRight: "-16px",
            paddingTop: "22px",
            paddingBottom: "34px",
            paddingLeft: "16px",
            paddingRight: "16px",
            borderLeftStyle: "solid",
            borderColor: settings.color.heart_red,
            borderLeftWidth: "3px",
          }}
        >
          <h4
            style={{
              fontSize: "18px",
              marginBottom: "9px",
              textDecorationLine: "underline",
              textDecorationColor: settings.color.heart_red,
              textUnderlineOffset: "3px",
              textDecorationThickness: "2px",
              textAlign: "left",
              marginTop: "0px",
            }}
          >
            Negatives
          </h4>
          <div
            style={{
              fontSize: "16px",
              textAlign: "left",
              margin: "0px",
            }}
          >
            {programme.negatives.map((p, index) => {
              return <Bullet key={`negative-point-${index}`}>{p}</Bullet>;
            })}
          </div>
        </div>
      )}
      {programme.cost && (
        <div style={{ marginTop: "26px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h4
              style={{
                fontSize: "18px",
                marginBottom: "9px",
                textAlign: "left",
                marginTop: "0px",
              }}
            >
              Cost
            </h4>
            <div style={{ flexDirection: "row", display: "flex" }}>
              {cost.map((i: JSX.Element) => {
                return i;
              })}
            </div>
          </div>
          <p
            style={{
              fontSize: "16px",
              textAlign: "left",
              margin: "0px",
            }}
          >
            {programme.costDescription}
          </p>
        </div>
      )}
      {programme.button && programme.button}
      {footer}
    </Container>
  );
};

RecommendationCard.propTypes = {
  programme: PropTypes.any,
  footer: PropTypes.any,
  match: PropTypes.number,
  onInfoClick: PropTypes.func,
};

export default RecommendationCard;
