import * as React from "react";
import SvgSplitScreen from "../components/SvgSplitScreen";
import settings from "../styles/global/settings";
import Layout from "../components/Layout/Layout";
import PropTypes from "prop-types";
import Image from "../svg/alcohol.svg";
import { SCREENS } from "../helpers/screen-names-helper";

const NoAlcoholScreen = ({
  onClick,
  onBackClick,
}: PropTypes.InferProps<typeof NoAlcoholScreen.propTypes>): JSX.Element => {
  const buttons = [
    {
      title: `Continue`,
      onClick: () => onClick(),
      showBack: true,
      onBackClick: () => onBackClick(),
    },
  ];
  return (
    <Layout
      segmentTitle={SCREENS.AlcoholInformationScreen}
      buttons={buttons}
      backgroundColor={settings.color.path}
    >
      <SvgSplitScreen
        backgroundColor={settings.color.primary_4}
        svg={<Image />}
      >
        <p>
          Some plans recommend abstaining from, or at least reducing intake of,
          alcohol—so that’s one thing to consider when weighing up the
          programmes on offer.
        </p>
      </SvgSplitScreen>
    </Layout>
  );
};

NoAlcoholScreen.propTypes = {
  onClick: PropTypes.func,
  onBackClick: PropTypes.func,
};

export default NoAlcoholScreen;
