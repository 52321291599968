import * as React from "react";
import SvgSplitScreen from "../components/SvgSplitScreen";
import settings from "../styles/global/settings";
import Layout from "../components/Layout/Layout";
import PropTypes from "prop-types";
import Dairy from "../svg/dairy.svg";
import { SCREENS } from "../helpers/screen-names-helper";

const DairyScreen = ({
  onClick,
  onBackClick,
}: PropTypes.InferProps<typeof DairyScreen.propTypes>): JSX.Element => {
  const buttons = [
    {
      title: `Continue`,
      onClick: () => onClick(),
      showBack: true,
      onBackClick: () => onBackClick(),
    },
  ];
  return (
    <Layout
      buttons={buttons}
      backgroundColor={settings.color.path}
      segmentTitle={SCREENS.NoDairyScreen}
    >
      <SvgSplitScreen
        backgroundColor={settings.color.primary_4}
        svg={<Dairy />}
      >
        <p>
          Most meal replacement products contain milk, so we wouldn’t recommend
          this approach for you. Let’s find out which of the other approaches
          would be better suited to your needs.
        </p>
      </SvgSplitScreen>
    </Layout>
  );
};

DairyScreen.propTypes = {
  onClick: PropTypes.func,
  onBackClick: PropTypes.func,
};

export default DairyScreen;
