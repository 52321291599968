import * as React from "react";
import settings from "../styles/global/settings";
import Layout from "../components/Layout/Layout";
import PropTypes from "prop-types";
import SelectList from "../components/SelectList";
import { toggleInSelection } from "./DietaryRequirementsQuestion";
import { SCREENS } from "../helpers/screen-names-helper";

export enum TriedBeforeOptions {
  COUNTING_CALORIES = "Counting calories or points",
  CUTTING_SUGAR = "Cutting out sugar",
  PT_OR_NUTRITIONIST = "Personal trainer or nutritionist",
  MEAL_REPLACEMENT = "Meal replacement shakes",
  DIETS = "Specific diet (keto, paleo, etc.)",
  GYM = "Joining a gym",
  FOOD_TRACKING = "Food-tracking app",
  SLEEP_TRACKING = "Sleep-tracking app",
  EXERCISE = "Exercise app",
  NONE = "None of the above",
}

const TriedBeforeQuestion = ({
  onClick,
  onBackClick,
  selectedOptions,
  setSelection,
}: PropTypes.InferProps<typeof TriedBeforeQuestion.propTypes>): JSX.Element => {
  const buttons = [
    {
      title: `Continue`,
      onClick: () => onClick(),
      showBack: true,
      onBackClick: () => onBackClick(),
      disabled: !selectedOptions || selectedOptions.length <= 0,
    },
  ];
  const options = [];
  Object.values(TriedBeforeOptions).forEach((value) => {
    options.push({
      id: value,
      text: value,
    });
  });
  return (
    <Layout
      showProgress
      group={2}
      index={0}
      buttons={buttons}
      backgroundColor={settings.color.path}
      title={"Which of the following have you tried in the past?"}
      subtitle={"Select all that apply"}
      segmentTitle={SCREENS.TriedBeforeQuestionScreen}
    >
      <SelectList
        multiselect
        smallPadding
        selectedItems={selectedOptions}
        onSelect={(id) => {
          toggleInSelection(id, selectedOptions, setSelection);
        }}
        items={options}
      />
    </Layout>
  );
};

TriedBeforeQuestion.propTypes = {
  onClick: PropTypes.func,
  onBackClick: PropTypes.func,
  selectedOptions: PropTypes.arrayOf(PropTypes.string),
  setSelection: PropTypes.func,
};

export default TriedBeforeQuestion;
