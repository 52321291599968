import * as React from "react";
import SvgSplitScreen from "../components/SvgSplitScreen";
import settings from "../styles/global/settings";
import Layout from "../components/Layout/Layout";
import PropTypes from "prop-types";
import Image from "../svg/lifestyle.svg";
import { SCREENS } from "../helpers/screen-names-helper";

const LifestyleCompleteScreen = ({
  onClick,
  onBackClick,
}: PropTypes.InferProps<
  typeof LifestyleCompleteScreen.propTypes
>): JSX.Element => {
  const buttons = [
    {
      title: `Continue`,
      onClick: () => onClick(),
      showBack: true,
      onBackClick: () => onBackClick(),
    },
  ];
  return (
    <Layout
      segmentTitle={SCREENS.LifestyleCompletedScreen}
      buttons={buttons}
      backgroundColor={settings.color.path}
    >
      <SvgSplitScreen
        backgroundColor={settings.color.primary_3}
        svg={<Image />}
      >
        <h4>
          Amazing! Your daily habits and preferences have been added to our
          recommendation engine
        </h4>
        <p>
          Whilst we cook up the best programme for you, the last few questions
          will ask about your motivations…
        </p>
      </SvgSplitScreen>
    </Layout>
  );
};

LifestyleCompleteScreen.propTypes = {
  onClick: PropTypes.func,
  onBackClick: PropTypes.func,
};

export default LifestyleCompleteScreen;
