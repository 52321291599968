import * as React from "react";
import { useState } from "react";
import { SCREENS } from "../helpers/screen-names-helper";
import RecommendationsScreen from "../screens/RecommendationsScreen";
import RecommendationDetailScreen from "../screens/RecommendationDetailScreen";
import BeansAndPulsesQuestion from "../screens/BeansAndPulsesQuestion";
import CarbsTypeQuestion from "../screens/CarbsTypeQuestion";
import CarbsQuestion, { CarbsOptions } from "../screens/CarbsQuestion";
import DietaryRequirementsQuestion, {
  Dietary,
} from "../screens/DietaryRequirementsQuestion";
import TriedBeforeQuestion, {
  TriedBeforeOptions,
} from "../screens/TriedBeforeQuestion";
import DairyScreen from "../screens/Dairy";
import ReverseDiabetesScreen from "../screens/ReverseDiabetes";
import PrediabetesScreen from "../screens/Prediabetes";
import LoseWeightScreen from "../screens/LoseWeight";
import WelcomeScreen from "../screens/Welcome";
import NoFruitScreen from "../screens/NoFruit";
import NearlyThereFoodScreen from "../screens/NearlyThereFood";
import NoAlcoholScreen from "../screens/NoAlcohol";
import FoodCompleteScreen from "../screens/FoodComplete";
import LifestyleCompleteScreen from "../screens/LifestyleComplete";
import QuizCompleteScreen from "../screens/QuizComplete";
import EmailScreen from "../screens/Email";
import HealthGoalQuestion, { Goal } from "../screens/HealthGoalQuestion";
import SpeedQuestion from "../screens/SpeedQuestion";
import SweetOrSavouryQuestion from "../screens/SweetOrSavouryQuestion";
import FatAndDairyQuestion from "../screens/FatAndDairyQuestion";
import FishMeatAndEggsQuestion from "../screens/FishMeatEggsQuestion";
import FruitAndVegQuestion, {
  FruitAndVegOptions,
} from "../screens/FruitAndVegQuestion";
import ConvenienceFoodQuestion from "../screens/ConvenienceFoodQuestion";
import AlcoholQuestion, { AlcoholOptions } from "../screens/AlcoholQuestion";
import DishQuestion from "../screens/DishQuestion";
import LifestyleQuestion from "../screens/LifestyleQuestion";
import EatingOutQuestion from "../screens/EatingOutQuestion";
import MealPlanningQuestion from "../screens/MealPlanningQuestion";
import CookingQuestion from "../screens/CookingQuestion";
import MealsQuestion from "../screens/MealsQuestion";
import UnsuccessfulQuestion from "../screens/UnsuccessfulQuestion";
import ConcernsQuestion from "../screens/ConcernsQuestion";
import MotivatorsQuestion from "../screens/MotivatorsQuestion";
import ToolsQuestion from "../screens/ToolsQuestion";
import { navigate } from "gatsby";
import { defaultAnswers, mockAnswers } from "../types/constants";
import { calculateScores } from "../helpers/recommendationEngine";
import { openInNewTab } from "../helpers/utility-helper";
import { trackButtonClick, trackProgrammeSelection } from "../helpers/segment-helper" 

const Home: React.FunctionComponent = (): JSX.Element => {
  const { GATSBY_DEBUG_MODE = false } = process.env || {}
  const [answers, setAnswers] = useState(GATSBY_DEBUG_MODE ? mockAnswers : defaultAnswers);
  const [screen, setScreen] = useState(SCREENS.Welcome);
  const flow = {};

  flow[SCREENS.Welcome] = (
    <WelcomeScreen
      onClick={() => {
        setScreen(SCREENS.GoalSelectScreen);
      }}
    />
  );

  flow[SCREENS.GoalSelectScreen] = (
    <HealthGoalQuestion
      goal={answers.goal}
      setGoal={(goal) => {
        setAnswers({ ...answers, goal });
      }}
      onClick={() => {
        trackButtonClick(SCREENS.GoalSelectScreen, answers.goal);
        if (answers.goal === Goal.Reverse) {
          setScreen(SCREENS.GoalDiabetesScreen);
        } else if (answers.goal === Goal.Prevent) {
          setScreen(SCREENS.GoalPrediabetesScreen);
        } else if (answers.goal === Goal.Weight) {
          setScreen(SCREENS.GoalWeightLossScreen);
        }
      }}
      onBackClick={() => {
        setScreen(SCREENS.Welcome);
      }}
    />
  );

  flow[SCREENS.GoalDiabetesScreen] = (
    <ReverseDiabetesScreen
      onClick={() => {
        setScreen(SCREENS.SpeedScreen);
      }}
      onBackClick={() => {
        setScreen(SCREENS.GoalSelectScreen);
      }}
    />
  );

  flow[SCREENS.GoalPrediabetesScreen] = (
    <PrediabetesScreen
      onClick={() => {
        setScreen(SCREENS.SpeedScreen);
      }}
      onBackClick={() => {
        setScreen(SCREENS.GoalSelectScreen);
      }}
    />
  );

  flow[SCREENS.GoalWeightLossScreen] = (
    <LoseWeightScreen
      onClick={() => {
        setScreen(SCREENS.SpeedScreen);
      }}
      onBackClick={() => {
        setScreen(SCREENS.GoalSelectScreen);
      }}
    />
  );

  flow[SCREENS.SpeedScreen] = (
    <SpeedQuestion
      onClick={() => {
        trackButtonClick(SCREENS.SpeedScreen, answers.speed);
        setScreen(SCREENS.DietaryScreen);
      }}
      onBackClick={() => {
        if (answers.goal === Goal.Reverse) {
          setScreen(SCREENS.GoalDiabetesScreen);
        } else if (answers.goal === Goal.Prevent) {
          setScreen(SCREENS.GoalPrediabetesScreen);
        } else if (answers.goal === Goal.Weight) {
          setScreen(SCREENS.GoalWeightLossScreen);
        }
      }}
      setSpeed={(speed) => {
        setAnswers({ ...answers, speed });
      }}
      speed={answers.speed}
    />
  );

  flow[SCREENS.DietaryScreen] = (
    <DietaryRequirementsQuestion
      onClick={() => {
        trackButtonClick(SCREENS.DietaryScreen, answers.dietary);
        if (
          answers.dietary.includes(Dietary.VEGAN) ||
          answers.dietary.includes(Dietary.LACTOSE)
        ) {
          setScreen(SCREENS.NoDairyScreen);
        } else {
          setScreen(SCREENS.SweetOrSavouryScreen);
        }
      }}
      onBackClick={() => {
        setScreen(SCREENS.SpeedScreen);
      }}
      dietaryRequirements={answers.dietary}
      setDietaryRequirements={(dietary) => {
        setAnswers({ ...answers, dietary });
      }}
    />
  );
  flow[SCREENS.NoDairyScreen] = (
    <DairyScreen
      onBackClick={() => {
        setScreen(SCREENS.DietaryScreen);
      }}
      onClick={() => {
        setScreen(SCREENS.SweetOrSavouryScreen);
      }}
    />
  );
  flow[SCREENS.SweetOrSavouryScreen] = (
    <SweetOrSavouryQuestion
      onClick={() => {
        trackButtonClick(SCREENS.SweetOrSavouryScreen, answers.sweetOrSavoury);
        setScreen(SCREENS.FatsScreen);
      }}
      onBackClick={() => {
        if (
          answers.dietary.includes(Dietary.VEGAN) ||
          answers.dietary.includes(Dietary.LACTOSE)
        ) {
          setScreen(SCREENS.NoDairyScreen);
        } else {
          setScreen(SCREENS.DietaryScreen);
        }
      }}
      selectedOption={answers.sweetOrSavoury}
      setSelection={(sweetOrSavoury) => {
        setAnswers({ ...answers, sweetOrSavoury });
      }}
    />
  );
  flow[SCREENS.FatsScreen] = (
    <FatAndDairyQuestion
      onClick={() => {
        trackButtonClick(SCREENS.FatsScreen, answers.fatsAndDairy);
        setScreen(SCREENS.FruitAndVegScreen);
      }}
      onBackClick={() => {
        setScreen(SCREENS.SweetOrSavouryScreen);
      }}
      selectedOption={answers.fatsAndDairy}
      setSelection={(fatsAndDairy) => {
        setAnswers({ ...answers, fatsAndDairy });
      }}
    />
  );
  flow[SCREENS.FruitAndVegScreen] = (
    <FruitAndVegQuestion
      onClick={() => {
        trackButtonClick(SCREENS.FruitAndVegScreen, answers.fruitAndVeg);
        if (answers.fruitAndVeg === FruitAndVegOptions.NEVER) {
          setScreen(SCREENS.NoFruitScreen);
        } else {
          if (answers.dietary.includes(Dietary.VEGAN)) {
            setScreen(SCREENS.BeansAndPulsesScreen);
          } else {
            setScreen(SCREENS.FishMeatEggsScreen);
          }
        }
      }}
      onBackClick={() => {
        setScreen(SCREENS.FatsScreen);
      }}
      selectedOption={answers.fruitAndVeg}
      setSelection={(fruitAndVeg) => {
        setAnswers({ ...answers, fruitAndVeg });
      }}
    />
  );
  flow[SCREENS.NoFruitScreen] = (
    <NoFruitScreen
      onClick={() => {
        if (answers.dietary.includes(Dietary.VEGAN)) {
          setScreen(SCREENS.BeansAndPulsesScreen);
        } else {
          setScreen(SCREENS.FishMeatEggsScreen);
        }
      }}
      onBackClick={() => {
        setScreen(SCREENS.FruitAndVegScreen);
      }}
    />
  );
  flow[SCREENS.FishMeatEggsScreen] = (
    <FishMeatAndEggsQuestion
      onClick={() => {
        trackButtonClick(SCREENS.FishMeatEggsScreen, answers.fishMeatEggs);
        setScreen(SCREENS.BeansAndPulsesScreen);
      }}
      onBackClick={() => {
        if (answers.fruitAndVeg === FruitAndVegOptions.NEVER) {
          setScreen(SCREENS.NoFruitScreen);
        } else {
          setScreen(SCREENS.FruitAndVegScreen);
        }
      }}
      selectedOption={answers.fishMeatEggs}
      setSelection={(fishMeatEggs) => {
        setAnswers({ ...answers, fishMeatEggs });
      }}
    />
  );
  flow[SCREENS.BeansAndPulsesScreen] = (
    <BeansAndPulsesQuestion
      onBackClick={() => {
        if (
          answers.dietary.includes(Dietary.VEGAN) &&
          answers.fruitAndVeg === FruitAndVegOptions.NEVER
        ) {
          setScreen(SCREENS.NoFruitScreen);
        } else if (answers.dietary.includes(Dietary.VEGAN)) {
          setScreen(SCREENS.FruitAndVegScreen);
        } else {
          setScreen(SCREENS.FishMeatEggsScreen);
        }
      }}
      onClick={() => {
        trackButtonClick(SCREENS.BeansAndPulsesScreen, answers.beansAndPulses);
        setScreen(SCREENS.ConvenienceScreen);
      }}
      selectedOption={answers.beansAndPulses}
      setSelection={(beansAndPulses) => {
        setAnswers({ ...answers, beansAndPulses });
      }}
    />
  );
  flow[SCREENS.ConvenienceScreen] = (
    <ConvenienceFoodQuestion
      onClick={() => {
        trackButtonClick(SCREENS.ConvenienceScreen, answers.convenienceFood);
        setScreen(SCREENS.NearlyThereScreen);
      }}
      onBackClick={() => {
        setScreen(SCREENS.BeansAndPulsesScreen);
      }}
      selectedOption={answers.convenienceFood}
      setSelection={(convenienceFood) => {
        setAnswers({ ...answers, convenienceFood });
      }}
    />
  );
  flow[SCREENS.NearlyThereScreen] = (
    <NearlyThereFoodScreen
      onClick={() => {
        setScreen(SCREENS.AlcoholQuestionScreen);
      }}
      onBackClick={() => {
        setScreen(SCREENS.ConvenienceScreen);
      }}
    />
  );
  flow[SCREENS.AlcoholQuestionScreen] = (
    <AlcoholQuestion
      onClick={() => {
        trackButtonClick(SCREENS.AlcoholQuestionScreen, answers.alcohol);
        if (answers.alcohol === AlcoholOptions.WEEKLY) {
          setScreen(SCREENS.AlcoholInformationScreen);
        } else {
          setScreen(SCREENS.CarbsQuestionScreen);
        }
      }}
      onBackClick={() => {
        setScreen(SCREENS.NearlyThereScreen);
      }}
      selectedOption={answers.alcohol}
      setSelection={(alcohol) => {
        setAnswers({ ...answers, alcohol });
      }}
    />
  );
  flow[SCREENS.AlcoholInformationScreen] = (
    <NoAlcoholScreen
      onBackClick={() => {
        setScreen(SCREENS.AlcoholQuestionScreen);
      }}
      onClick={() => {
        setScreen(SCREENS.CarbsQuestionScreen);
      }}
    />
  );
  flow[SCREENS.CarbsQuestionScreen] = (
    <CarbsQuestion
      onBackClick={() => {
        if (answers.alcohol === AlcoholOptions.WEEKLY) {
          setScreen(SCREENS.AlcoholInformationScreen);
        } else {
          setScreen(SCREENS.AlcoholQuestionScreen);
        }
      }}
      onClick={() => {
        trackButtonClick(SCREENS.CarbsQuestionScreen, answers.carbs);
        if (answers.carbs === CarbsOptions.NONE) {
          setScreen(SCREENS.DishQuestionScreen);
        } else {
          setScreen(SCREENS.CarbsTypeQuestionScreen);
        }
      }}
      selectedOption={answers.carbs}
      setSelection={(carbs) => {
        setAnswers({ ...answers, carbs });
      }}
    />
  );
  flow[SCREENS.CarbsTypeQuestionScreen] = (
    <CarbsTypeQuestion
      onBackClick={() => {
        setScreen(SCREENS.CarbsQuestionScreen);
      }}
      onClick={() => {
        trackButtonClick(SCREENS.CarbsTypeQuestionScreen, answers.carbType);
        setScreen(SCREENS.DishQuestionScreen);
      }}
      selectedOption={answers.carbType}
      setSelection={(carbType) => {
        setAnswers({ ...answers, carbType });
      }}
    />
  );
  flow[SCREENS.DishQuestionScreen] = (
    <DishQuestion
      onBackClick={() => {
        if (answers.carbs === CarbsOptions.NONE) {
          setScreen(SCREENS.CarbsQuestionScreen);
        } else {
          setScreen(SCREENS.CarbsTypeQuestionScreen);
        }
      }}
      onClick={() => {
        trackButtonClick(SCREENS.DishQuestionScreen, answers.dish);
        setScreen(SCREENS.FoodPreferencesCompleteScreen);
      }}
      selectedOption={answers.dish}
      setSelection={(dish) => {
        setAnswers({ ...answers, dish });
      }}
    />
  );
  flow[SCREENS.FoodPreferencesCompleteScreen] = (
    <FoodCompleteScreen
      onBackClick={() => {
        setScreen(SCREENS.DishQuestionScreen);
      }}
      onClick={() => {
        setScreen(SCREENS.LifestyleQuestionScreen);
      }}
    />
  );
  flow[SCREENS.LifestyleQuestionScreen] = (
    <LifestyleQuestion
      onBackClick={() => {
        setScreen(SCREENS.FoodPreferencesCompleteScreen);
      }}
      onClick={() => {
        trackButtonClick(SCREENS.LifestyleQuestionScreen, answers.lifestyle);
        setScreen(SCREENS.EatingOutQuestionScreen);
      }}
      selectedOption={answers.lifestyle}
      setSelection={(lifestyle) => {
        setAnswers({ ...answers, lifestyle });
      }}
    />
  );
  flow[SCREENS.EatingOutQuestionScreen] = (
    <EatingOutQuestion
      onBackClick={() => {
        setScreen(SCREENS.LifestyleQuestionScreen);
      }}
      onClick={() => {
        trackButtonClick(SCREENS.EatingOutQuestionScreen, answers.eatingOut);
        setScreen(SCREENS.MealPlanningQuestionScreen);
      }}
      selectedOption={answers.eatingOut}
      setSelection={(eatingOut) => {
        setAnswers({ ...answers, eatingOut });
      }}
    />
  );
  flow[SCREENS.MealPlanningQuestionScreen] = (
    <MealPlanningQuestion
      onBackClick={() => {
        setScreen(SCREENS.EatingOutQuestionScreen);
      }}
      onClick={() => {
        trackButtonClick(SCREENS.MealPlanningQuestionScreen, answers.mealPrep);
        setScreen(SCREENS.CookingQuestionScreen);
      }}
      selectedOption={answers.mealPrep}
      setSelection={(mealPrep) => {
        setAnswers({ ...answers, mealPrep });
      }}
    />
  );
  flow[SCREENS.CookingQuestionScreen] = (
    <CookingQuestion
      onBackClick={() => {
        setScreen(SCREENS.MealPlanningQuestionScreen);
      }}
      onClick={() => {
        trackButtonClick(SCREENS.CookingQuestionScreen, answers.cooking);
        setScreen(SCREENS.MealsQuestionScreen);
      }}
      selectedOption={answers.cooking}
      setSelection={(cooking) => {
        setAnswers({ ...answers, cooking });
      }}
    />
  );
  flow[SCREENS.MealsQuestionScreen] = (
    <MealsQuestion
      onBackClick={() => {
        setScreen(SCREENS.CookingQuestionScreen);
      }}
      onClick={() => {
        trackButtonClick(SCREENS.MealsQuestionScreen, answers.meals);
        setScreen(SCREENS.LifestyleCompletedScreen);
      }}
      selectedOption={answers.meals}
      setSelection={(meals) => {
        setAnswers({ ...answers, meals });
      }}
    />
  );
  flow[SCREENS.LifestyleCompletedScreen] = (
    <LifestyleCompleteScreen
      onBackClick={() => {
        setScreen(SCREENS.MealsQuestionScreen);
      }}
      onClick={() => {
        setScreen(SCREENS.TriedBeforeQuestionScreen);
      }}
    />
  );
  flow[SCREENS.TriedBeforeQuestionScreen] = (
    <TriedBeforeQuestion
      onBackClick={() => {
        setScreen(SCREENS.LifestyleCompletedScreen);
      }}
      onClick={() => {
        trackButtonClick(SCREENS.TriedBeforeQuestionScreen, answers.triedBefore);
        if (answers.triedBefore.includes(TriedBeforeOptions.NONE)) {
          setScreen(SCREENS.ConcernsQuestionScreen);
        } else {
          setScreen(SCREENS.UnsuccessfulQuestionScreen);
        }
      }}
      selectedOptions={answers.triedBefore}
      setSelection={(triedBefore) => {
        setAnswers({ ...answers, triedBefore });
      }}
    />
  );
  flow[SCREENS.UnsuccessfulQuestionScreen] = (
    <UnsuccessfulQuestion
      onBackClick={() => {
        setScreen(SCREENS.TriedBeforeQuestionScreen);
      }}
      onClick={() => {
        trackButtonClick(SCREENS.UnsuccessfulQuestionScreen, answers.unsuccessful);
        setScreen(SCREENS.ConcernsQuestionScreen);
      }}
      selectedOption={answers.unsuccessful}
      setSelection={(unsuccessful) => {
        setAnswers({ ...answers, unsuccessful });
      }}
    />
  );
  flow[SCREENS.ConcernsQuestionScreen] = (
    <ConcernsQuestion
      onBackClick={() => {
        if (answers.triedBefore.includes(TriedBeforeOptions.NONE)) {
          setScreen(SCREENS.TriedBeforeQuestionScreen);
        } else {
          setScreen(SCREENS.UnsuccessfulQuestionScreen);
        }
      }}
      onClick={() => {
        trackButtonClick(SCREENS.ConcernsQuestionScreen, answers.concerns);
        setScreen(SCREENS.MotivatorsQuestionScreen);
      }}
      selectedOption={answers.concerns}
      setSelection={(concerns) => {
        setAnswers({ ...answers, concerns });
      }}
    />
  );
  flow[SCREENS.MotivatorsQuestionScreen] = (
    <MotivatorsQuestion
      onBackClick={() => {
        setScreen(SCREENS.ConcernsQuestionScreen);
      }}
      onClick={() => {
        trackButtonClick(SCREENS.MotivatorsQuestionScreen, answers.motivator);
        setScreen(SCREENS.ToolsQuestionScreen);
      }}
      selectedOption={answers.motivator}
      setSelection={(motivator) => {
        setAnswers({ ...answers, motivator });
      }}
    />
  );
  flow[SCREENS.ToolsQuestionScreen] = (
    <ToolsQuestion
      onBackClick={() => {
        setScreen(SCREENS.MotivatorsQuestionScreen);
      }}
      onClick={() => {
        trackButtonClick(SCREENS.ToolsQuestionScreen, answers.tools);
        setScreen(SCREENS.QuizCompleteScreen);
      }}
      selectedOption={answers.tools}
      setSelection={(tools) => {
        setAnswers({ ...answers, tools });
      }}
    />
  );
  flow[SCREENS.QuizCompleteScreen] = (
    <QuizCompleteScreen
      onBackClick={() => {
        setScreen(SCREENS.ToolsQuestionScreen);
      }}
      onClick={() => {
        const scores = calculateScores(answers);
        setAnswers({ ...answers, scores });
        setScreen(SCREENS.RecommendationScreen);
      }}
    />
  );
  flow[SCREENS.RecommendationScreen] = (
    <RecommendationsScreen
      scores={answers.scores}
      onInfoClick={(programmeDetail) => {
        setAnswers({ ...answers, programmeDetail });
        setScreen(SCREENS.RecommendationDetailScreen);
      }}
      onClick={(selectedProgramme, sortedProgrammes) => {
        setAnswers({ ...answers, selectedProgramme });
        trackProgrammeSelection(sortedProgrammes, selectedProgramme)
        setScreen(SCREENS.EmailScreen);
      }}
      indexInFocus={answers.programmeDetail.index}
    />
  );
  flow[SCREENS.RecommendationDetailScreen] = (
    <RecommendationDetailScreen
      onBackClick={() => {
        setScreen(SCREENS.RecommendationScreen);
      }}
      onClick={
        answers.programmeDetail.url
          ? () => {
              openInNewTab(answers.programmeDetail.url);
            }
          : null
      }
      content={answers.programmeDetail.content}
      title={answers.programmeDetail.title}
    />
  );
  flow[SCREENS.EmailScreen] = (
    <EmailScreen
      onBackClick={() => {
        setScreen(SCREENS.RecommendationScreen);
      }}
      onClick={() => {
        if (window && window.location) {
          window.location.href = `https://www.tryhabitual.com/thanks`;
        }
      }}
      email={answers.email}
      setEmail={(email) => setAnswers({ ...answers, email })}
      answers={answers}
      setGdpr={(gdpr) => setAnswers({ ...answers, gdpr })}
      setNewsletter={(newsletter) => setAnswers({ ...answers, newsletter })}
    />
  );

  if (!flow[screen]) {
    console.log("ERROR: Screen doesnt exist", screen);
    navigate("/notfound");
  }

  return flow[screen];
};

export default Home;
