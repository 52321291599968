import { AlcoholOptions } from "../screens/AlcoholQuestion";
import { BeansAndPulsesOptions } from "../screens/BeansAndPulsesQuestion";
import { CarbsOptions } from "../screens/CarbsQuestion";
import { CarbsTypeOptions } from "../screens/CarbsTypeQuestion";
import { ConvenienceOptions } from "../screens/ConvenienceFoodQuestion";
import { CookingOptions } from "../screens/CookingQuestion";
import { Dietary } from "../screens/DietaryRequirementsQuestion";
import { DishOptions } from "../screens/DishQuestion";
import { EatingOutOptions } from "../screens/EatingOutQuestion";
import { FatsAndDairyOptions } from "../screens/FatAndDairyQuestion";
import { FishMeatEggsOptions } from "../screens/FishMeatEggsQuestion";
import { FruitAndVegOptions } from "../screens/FruitAndVegQuestion";
import { LifestyleOptions } from "../screens/LifestyleQuestion";
import { MealPlanningOptions } from "../screens/MealPlanningQuestion";
import { MealsOptions } from "../screens/MealsQuestion";
import { Speed } from "../screens/SpeedQuestion";
import { SweetOrSavoury } from "../screens/SweetOrSavouryQuestion";
import { UnsuccessfulOptions } from "../screens/UnsuccessfulQuestion";
import { Answers, Scores, ProgrammeTypes } from "../types/constants";

export const calculateScores = (answers: Answers): Scores[] => {
  let tdrScore = 0;
  let tdrPlusScore = 0;
  let ketoScore = 0;
  let lowCarbScore = 0;
  let wholefoodsScore = 0;

  if (answers.speed) {
    if (answers.speed === Speed.Quick) {
      tdrScore++;
      tdrPlusScore++;
    } else if (answers.speed === Speed.Long) {
      tdrScore++;
      tdrPlusScore++;
      ketoScore++;
      lowCarbScore++;
      wholefoodsScore++;
    }
  }

  if (answers.dietary && answers.dietary.length > 0) {
    if (
      answers.dietary.includes(Dietary.VEGAN) ||
      answers.dietary.includes(Dietary.VEGETARIAN) ||
      answers.dietary.includes(Dietary.PESCATARIAN)
    ) {
      wholefoodsScore++;
    }
    if (
      answers.dietary.includes(Dietary.VEGAN) ||
      answers.dietary.includes(Dietary.LACTOSE)
    ) {
      tdrScore--;
      tdrPlusScore--;
    }
  }

  if (answers.sweetOrSavoury) {
    if (answers.sweetOrSavoury === SweetOrSavoury.SWEET) {
      tdrScore++;
      tdrPlusScore++;
    } else if (answers.sweetOrSavoury === SweetOrSavoury.SAVOURY) {
      ketoScore++;
      wholefoodsScore++;
      lowCarbScore++;
    }
  }

  if (
    answers.fatsAndDairy &&
    answers.fatsAndDairy === FatsAndDairyOptions.OFTEN
  ) {
    ketoScore++;
    lowCarbScore++;
  }

  if (answers.fruitAndVeg && answers.fruitAndVeg === FruitAndVegOptions.OFTEN) {
    wholefoodsScore++;
    lowCarbScore++;
  }

  if (
    answers.fishMeatEggs &&
    answers.fishMeatEggs === FishMeatEggsOptions.OFTEN
  ) {
    ketoScore++;
  }

  if (
    answers.beansAndPulses &&
    answers.beansAndPulses === BeansAndPulsesOptions.OFTEN
  ) {
    wholefoodsScore++;
    ketoScore++;
  }

  if (
    answers.convenienceFood &&
    answers.convenienceFood === ConvenienceOptions.OFTEN
  ) {
    tdrScore++;
  }

  if (answers.alcohol && answers.alcohol === AlcoholOptions.WEEKLY) {
    tdrScore--;
  }

  if (answers.carbs) {
    if (answers.carbs === CarbsOptions.NEED) {
      wholefoodsScore++;
    } else if (answers.carbs === CarbsOptions.LIKE) {
      lowCarbScore++;
    } else if (answers.carbs === CarbsOptions.OK) {
      ketoScore++;
    } else if (answers.carbs === CarbsOptions.NONE) {
      tdrPlusScore++;
      tdrScore++;
    }
  }

  if (answers.carbType) {
    if (answers.carbType === CarbsTypeOptions.WHOLEGRAIN) {
      wholefoodsScore++;
    } else if (answers.carbType === CarbsTypeOptions.REFINED) {
      lowCarbScore++;
    }
  }

  if (answers.dish) {
    if (answers.dish === DishOptions.PASTA) {
      lowCarbScore--;
      ketoScore--;
    } else if (answers.dish === DishOptions.SURF_AND_TURF) {
      ketoScore++;
    } else if (answers.dish === DishOptions.POKE_BOWL) {
      wholefoodsScore++;
      lowCarbScore++;
    } else if (answers.dish === DishOptions.NONE) {
      tdrPlusScore++;
      tdrScore++;
    }
  }

  if (
    answers.lifestyle &&
    (answers.lifestyle === LifestyleOptions.BUSY ||
      answers.lifestyle === LifestyleOptions.WORK)
  ) {
    tdrScore++;
  }

  if (answers.eatingOut) {
    if (answers.eatingOut === EatingOutOptions.WEEKLY) {
      tdrScore--;
      wholefoodsScore--;
    } else {
      ketoScore++;
      lowCarbScore++;
      tdrPlusScore++;
    }
  }

  if (
    answers.mealPrep &&
    (answers.mealPrep === MealPlanningOptions.VERY_DIFFICULT ||
      answers.mealPrep === MealPlanningOptions.DIFFICULT)
  ) {
    tdrScore++;
  }

  if (answers.cooking) {
    if (
      answers.cooking === CookingOptions.LOVE ||
      answers.cooking === CookingOptions.LIKE
    ) {
      ketoScore++;
      wholefoodsScore++;
      lowCarbScore++;
    } else if (
      answers.cooking === CookingOptions.EAT ||
      answers.cooking === CookingOptions.HATE
    ) {
      tdrScore++;
      tdrPlusScore++;
    }
  }

  if (
    answers.meals &&
    (answers.meals === MealsOptions.ONE ||
      answers.meals === MealsOptions.TWO ||
      answers.meals === MealsOptions.THREE)
  ) {
    tdrPlusScore++;
  }

  if (answers.unsuccessful) {
    if (answers.unsuccessful === UnsuccessfulOptions.CONFUSING) {
      tdrScore++;
      tdrPlusScore++;
    } else if (answers.unsuccessful === UnsuccessfulOptions.RESTRICTIVE) {
      tdrPlusScore--;
      tdrScore--;
      wholefoodsScore--;
      ketoScore++;
      lowCarbScore++;
    }
  }

  const scores: Scores[] = [
    {
      type: ProgrammeTypes.TDR,
      score: tdrScore,
      match: Math.round(Math.round(((tdrScore + 4) / 14) * 100) / 2 + 50),
    },
    {
      type: ProgrammeTypes.TDR_PLUS,
      score: tdrPlusScore,
      match: Math.round(Math.round(((tdrPlusScore + 2) / 11) * 100) / 2 + 50),
    },
    {
      type: ProgrammeTypes.KETO,
      score: ketoScore,
      match: Math.round(Math.round(((ketoScore + 1) / 11) * 100) / 2 + 50),
    },
    {
      type: ProgrammeTypes.LOW_CARB,
      score: lowCarbScore,
      match: Math.round(Math.round(((lowCarbScore + 1) / 11) * 100) / 2 + 50),
    },
    {
      type: ProgrammeTypes.WHOLEFOODS,
      score: wholefoodsScore,
      match: Math.round(
        Math.round(((wholefoodsScore + 2) / 11) * 100) / 2 + 50
      ),
    },
  ];
  return scores;
};
