import * as React from "react";
import settings from "../styles/global/settings";
import Layout from "../components/Layout/Layout";
import PropTypes from "prop-types";
import SelectList from "../components/SelectList";
import { SCREENS } from "../helpers/screen-names-helper";

export enum CarbsTypeOptions {
  REFINED = "Refined\n(white bread, white rice etc)",
  WHOLEGRAIN = "Wholegrain\n(brown bread, brown rice etc)",
}

const CarbsTypeQuestion = ({
  onClick,
  onBackClick,
  selectedOption,
  setSelection,
}: PropTypes.InferProps<typeof CarbsTypeQuestion.propTypes>): JSX.Element => {
  const buttons = [
    {
      title: `Continue`,
      onClick: () => onClick(),
      showBack: true,
      onBackClick: () => onBackClick(),
      disabled: !selectedOption,
    },
  ];
  const options = [];
  Object.values(CarbsTypeOptions).forEach((value) => {
    options.push({
      id: value,
      text: value.split('\n')[0],
      subText: value.split('\n')[1],
    });
  });
  return (
    <Layout
      showProgress
      group={0}
      index={11}
      buttons={buttons}
      backgroundColor={settings.color.path}
      title={"Which carbs do you prefer?"}
      subtitle={"Please select one"}
      segmentTitle={SCREENS.CarbsTypeQuestionScreen}
    >
      <SelectList
        smallPadding
        selectedItems={[selectedOption]}
        onSelect={(id) => {
          setSelection(id);
        }}
        items={options}
      />
    </Layout>
  );
};

CarbsTypeQuestion.propTypes = {
  onClick: PropTypes.func,
  onBackClick: PropTypes.func,
  selectedOption: PropTypes.string,
  setSelection: PropTypes.func,
};

export default CarbsTypeQuestion;
