import React from "react";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import PropTypes from "prop-types";

function Animation(
  props: PropTypes.InferProps<typeof Animation.propTypes>
): JSX.Element {
  const { animation, style, showControls, loop, autoplay } = props;
  return (
    <Player
      autoplay={autoplay || false}
      loop={loop || false}
      src={animation}
      style={style}
    >
      <Controls visible={showControls} buttons={[]} />
    </Player>
  );
}

Animation.propTypes = {
  animation: PropTypes.any,
  style: PropTypes.any,
  showControls: PropTypes.bool,
  loop: PropTypes.bool,
  autoplay: PropTypes.bool,
};

export default Animation;
