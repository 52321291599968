import * as React from "react";
import settings from "../styles/global/settings";
import Layout from "../components/Layout/Layout";
import PropTypes from "prop-types";
import SelectList from "../components/SelectList";
import { SCREENS } from "../helpers/screen-names-helper";

export enum CookingOptions {
  HATE = "I hate it",
  EAT = "I cook to eat",
  DONT_MIND = "I don't mind",
  LIKE = "I like it",
  LOVE = "I love it",
}

const CookingQuestion = ({
  onClick,
  onBackClick,
  selectedOption,
  setSelection,
}: PropTypes.InferProps<typeof CookingQuestion.propTypes>): JSX.Element => {
  const buttons = [
    {
      title: `Continue`,
      onClick: () => onClick(),
      showBack: true,
      onBackClick: () => onBackClick(),
      disabled: !selectedOption,
    },
  ];
  const options = [];
  Object.values(CookingOptions).forEach((value) => {
    options.push({
      id: value,
      text: value,
    });
  });
  return (
    <Layout
      showProgress
      group={1}
      index={3}
      buttons={buttons}
      backgroundColor={settings.color.path}
      title={"How much do you enjoy cooking?"}
      subtitle={"Please select one"}
      segmentTitle={SCREENS.CookingQuestionScreen}
    >
      <SelectList
        smallPadding
        selectedItems={[selectedOption]}
        onSelect={(id) => {
          setSelection(id);
        }}
        items={options}
      />
    </Layout>
  );
};

CookingQuestion.propTypes = {
  onClick: PropTypes.func,
  onBackClick: PropTypes.func,
  selectedOption: PropTypes.string,
  setSelection: PropTypes.func,
};

export default CookingQuestion;
