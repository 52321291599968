import * as React from "react";
import AnimationSplitScreen from "../components/AnimationSplitScreen";
import settings from "../styles/global/settings";
import Layout from "../components/Layout/Layout";
import PropTypes from "prop-types";
import { SCREENS } from "../helpers/screen-names-helper";

const ReverseDiabetesScreen = ({
  onClick,
  onBackClick,
}: PropTypes.InferProps<
  typeof ReverseDiabetesScreen.propTypes
>): JSX.Element => {
  const buttons = [
    {
      title: `Continue`,
      onClick: () => onClick(),
      showBack: true,
      onBackClick: () => onBackClick(),
    },
  ];
  return (
    <Layout
      segmentTitle={SCREENS.GoalDiabetesScreen}
      buttons={buttons}
      backgroundColor={settings.color.path}
    >
      <AnimationSplitScreen
        backgroundColor={settings.color.primary_4}
        animation={require("../animations/health goal selected.json")}
      >
        <h4>Amazing! We can help you with that.</h4>
        <p>
          Up to 60% of people who lose at least 15kg of weight can reverse type
          2 diabetes.
        </p>
        <p>
          There’s no single perfect way to lose 15kg, which is why we need to
          find an approach that will work for you! The following questions will
          help us recommend the plan best suited to your lifestyle.
        </p>
      </AnimationSplitScreen>
    </Layout>
  );
};

ReverseDiabetesScreen.propTypes = {
  onClick: PropTypes.func,
  onBackClick: PropTypes.func,
};

export default ReverseDiabetesScreen;
