import * as React from "react";
import settings from "../styles/global/settings";
import Layout from "../components/Layout/Layout";
import PropTypes from "prop-types";
import SelectList from "../components/SelectList";
import { SCREENS } from "../helpers/screen-names-helper";

export enum Dietary {
  VEGETARIAN = "Vegetarian",
  FLEXITARIAN = "Flexitarian",
  PESCATARIAN = "Pescatarian",
  VEGAN = "Vegan",
  CELIAC = "Celiac",
  GLUTEN = "Gluten intolerance",
  LACTOSE = "Lactose intolerance",
  NONE = "None of the above",
}

export const toggleInSelection = (
  answerId: string,
  answers: string[],
  onChange: (newArray: string[]) => void
): void => {
  // if in list remove it, if not add it.
  const exists = answers.includes(answerId);
  if (answerId === "None of the above") {
    answers = ["None of the above"];
  } else if (exists) {
    const index = answers.indexOf(answerId);
    answers.splice(index, 1);
  } else {
    if (answers.indexOf("None of the above") >= 0) {
      const index = answers.indexOf("None of the above");
      answers[index] = answerId;
    } else {
      answers.push(answerId);
    }
  }
  onChange(answers);
};

const DietaryRequirementsQuestion = ({
  onClick,
  onBackClick,
  dietaryRequirements,
  setDietaryRequirements,
}: PropTypes.InferProps<
  typeof DietaryRequirementsQuestion.propTypes
>): JSX.Element => {
  const buttons = [
    {
      title: `Continue`,
      onClick: () => onClick(),
      showBack: true,
      onBackClick: () => onBackClick(),
      disabled: !dietaryRequirements || dietaryRequirements.length <= 0,
    },
  ];
  const options = [];
  Object.values(Dietary).forEach((value) => {
    options.push({
      id: value,
      text: value,
    });
  });
  return (
    <Layout
      showProgress
      group={0}
      index={2}
      buttons={buttons}
      backgroundColor={settings.color.path}
      title={"Do you have any dietary requirements?"}
      subtitle={"Select all that apply"}
      segmentTitle={SCREENS.DietaryScreen}
    >
      <SelectList
        multiselect
        smallPadding
        selectedItems={dietaryRequirements}
        onSelect={(id) => {
          toggleInSelection(id, dietaryRequirements, setDietaryRequirements);
        }}
        items={options}
      />
    </Layout>
  );
};

DietaryRequirementsQuestion.propTypes = {
  onClick: PropTypes.func,
  onBackClick: PropTypes.func,
  dietaryRequirements: PropTypes.arrayOf(PropTypes.string),
  setDietaryRequirements: PropTypes.func,
};

export default DietaryRequirementsQuestion;
