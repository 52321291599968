import * as React from "react";
import AnimationSplitScreen from "../components/AnimationSplitScreen";
import settings from "../styles/global/settings";
import Layout from "../components/Layout/Layout";
import PropTypes from "prop-types";
import { SCREENS } from "../helpers/screen-names-helper";

const LoseWeightScreen = ({
  onClick,
  onBackClick,
}: PropTypes.InferProps<typeof LoseWeightScreen.propTypes>): JSX.Element => {
  const buttons = [
    {
      title: `Continue`,
      onClick: () => onClick(),
      showBack: true,
      onBackClick: () => onBackClick(),
    },
  ];
  return (
    <Layout
      segmentTitle={SCREENS.GoalWeightLossScreen}
      buttons={buttons}
      backgroundColor={settings.color.path}
    >
      <AnimationSplitScreen
        backgroundColor={settings.color.primary_4}
        animation={require("../animations/health goal selected.json")}
      >
        <h4>Amazing! We can help you with that.</h4>
        <p>Habitual patients have lost up 40kg, and kept it off!</p>
        <p>
          There’s no single perfect way to lose weight and keep it off, which is
          why we need to find an approach that will work for you! The following
          questions will help us recommend the plan best suited to your
          lifestyle.
        </p>
      </AnimationSplitScreen>
    </Layout>
  );
};

LoseWeightScreen.propTypes = {
  onClick: PropTypes.func,
  onBackClick: PropTypes.func,
};

export default LoseWeightScreen;
