import * as React from "react";
import AnimationSplitScreen from "../components/AnimationSplitScreen";
import settings from "../styles/global/settings";
import Layout from "../components/Layout/Layout";
import PropTypes from "prop-types";
import { SCREENS } from "../helpers/screen-names-helper";

const QuizCompleteScreen = ({
  onClick,
  onBackClick,
}: PropTypes.InferProps<typeof QuizCompleteScreen.propTypes>): JSX.Element => {
  const buttons = [
    {
      title: `Yes, show me!`,
      onClick: () => onClick(),
      showBack: true,
      onBackClick: () => onBackClick(),
    },
  ];
  return (
    <Layout segmentTitle={SCREENS.QuizCompleteScreen} buttons={buttons} backgroundColor={settings.color.path}>
      <AnimationSplitScreen
        backgroundColor={settings.color.primary_2}
        animation={require("../animations/quiz complete.json")}
      >
        <h4>Congratulations!</h4>
        <p>You’re one tap away from your 5 recommendations.</p>
        <p>Are you ready to choose your favourite?</p>
      </AnimationSplitScreen>
    </Layout>
  );
};

QuizCompleteScreen.propTypes = {
  onClick: PropTypes.func,
  onBackClick: PropTypes.func,
};

export default QuizCompleteScreen;
