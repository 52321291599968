import * as React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import Animation from "./Animation";

interface AnimationContainerProps {
  backgroundColor?: string;
}

const AnimationContainer = styled.div.attrs(() => ({
  className: "AnimationContainer",
}))`
  width: 100%;
  min-height: 215px;
  ${(props: AnimationContainerProps) =>
    props.backgroundColor &&
    css`
        background-color: ${props.backgroundColor};
          }
        `};
`;

const ContentContainer = styled.div.attrs(() => ({
  className: "ContentContainer",
}))`
  max-width: 375px;
  margin: auto;
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
  flex: 1;
  padding-bottom: 100px;
`;

const AnimationSplitScreen = ({
  animation,
  backgroundColor,
  children,
}: PropTypes.InferProps<
  typeof AnimationSplitScreen.propTypes
>): JSX.Element => {
  return (
    <>
      <AnimationContainer backgroundColor={backgroundColor}>
        <div style={{ maxWidth: "600px", margin: "auto" }}>
          <Animation
            animation={animation}
            autoplay
            loop
            style={{
              maxWidth: "100%",
            }}
          />
        </div>
      </AnimationContainer>
      <ContentContainer>{children}</ContentContainer>
    </>
  );
};

AnimationSplitScreen.propTypes = {
  animation: PropTypes.any,
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
};

export default AnimationSplitScreen;
