import * as React from "react";
import settings from "../styles/global/settings";
import Layout from "../components/Layout/Layout";
import PropTypes from "prop-types";
import SelectList from "../components/SelectList";
import { SCREENS } from "../helpers/screen-names-helper";

export enum Speed {
  Quick = "Quicker the better",
  Long = "As long as it takes",
  Slow = "Slow and steady",
}

const SpeedQuestion = ({
  onClick,
  onBackClick,
  speed,
  setSpeed,
}: PropTypes.InferProps<typeof SpeedQuestion.propTypes>): JSX.Element => {
  const buttons = [
    {
      title: `Continue`,
      onClick: () => onClick(),
      showBack: true,
      onBackClick: () => onBackClick(),
      disabled: !speed,
    },
  ];
  return (
    <Layout
      showProgress
      group={0}
      index={1}
      buttons={buttons}
      backgroundColor={settings.color.path}
      title={"How fast do you want results?"}
      subtitle={"Please select one"}
      segmentTitle={SCREENS.SpeedScreen}
    >
      <SelectList
        smallPadding
        selectedItems={[speed]}
        onSelect={(id) => {
          setSpeed(id);
        }}
        items={[
          {
            id: Speed.Quick,
            text: Speed.Quick,
          },
          {
            id: Speed.Long,
            text: Speed.Long,
          },
          {
            id: Speed.Slow,
            text: Speed.Slow,
          },
        ]}
      />
    </Layout>
  );
};

SpeedQuestion.propTypes = {
  onClick: PropTypes.func,
  onBackClick: PropTypes.func,
  speed: PropTypes.string,
  setSpeed: PropTypes.func,
};

export default SpeedQuestion;
